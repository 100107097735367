import React from 'react'


function Nav(){
    return(
        <nav className="navbar navbar-dark bg-dark text-light" id="nav1">Business Standard, the single stack solution more profitable, scalable and elastic for your organizational needs</nav>

    )

}
export default Nav;